import { Injectable } from '@angular/core';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { IGetQuery } from 'ets-fe-ng-sdk';
import { ICode, ISaveCode } from './code.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CodeService extends BaseService {
  protected override baseURL: string = 'v1/codes/';

  protected save(data: ISaveCode[]) {
    return this.post<ICode[]>(data, '');
  }
  search=(
    query: IGetQuery<{
      codeSubGroup: string;
      codeGroup: string;
      code: string;
      codeTitle: string;
      codeCat: string;
      recType: string;
      active: number;
    }>,
  ) => this.get<ISR<ICode>>('search', query)

  fetchSubgroups() {
    return this.get<string[]>('codeSubGroup');
  }
  create(data: ISaveCode[]) {
    return this.save(data);
  }
  update(data: ISaveCode[]) {
    return this.save(data);
  }
  deleteByID(id: number) {
    return this.delete(id.toString());
  }
}
